import React, {useState, useEffect} from 'react';
import isEmpty from 'lodash/isEmpty';
import {PropTypes} from 'prop-types';
import {Input} from '@gs-ux-uitoolkit-react/input';
import cn from 'classnames';
import DOMPurify from 'dompurify';
import {usePasswordVisibilityIcon} from '../Login/loginHook';
import LeftPanel from '../../core/LeftPanel';
import Button from '../../core/Button';
import ValidationItem from '../ValidationItem';
import HelpContainer from '../../../containers/HelpContainer';
import Constants from '../../../constants/appConstants';
import LoginConstants from '../../../constants/loginConstants';
import {getLoginRoute, hasFoundRepeatingCharacter, hasFoundSequentialCharacter} from '../../../utils/commonUtils';
import {Conditional} from '../../core/Conditional';
import {ThreeSquaresLoader} from '../../core/Loaders';
import {analyticsEventName, getCreatePwdAnalytics} from './analytics';
import {SessionStorage} from '../../../utils/storage';
import './index.scss';

export const CreatePassword = (props) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const {
    passwordEnabled: newPasswordEnabled,
    passwordTrailingContent: newPasswordTrailingContent
  } = usePasswordVisibilityIcon();
  const {
    passwordEnabled: confirmPasswordEnabled,
    passwordTrailingContent: confirmPasswordTrailingContent
  } = usePasswordVisibilityIcon();

  useEffect(() => {
    if (!props.userId) {
      props.history.push(getLoginRoute(Constants.LOGIN_ROUTE));
    }
  }, [props.userId]);

  useEffect(() => {
    const forgotPwdInitTimeStamp = SessionStorage.getItem(LoginConstants.FORGOT_PWD_INIT_TIMESTAMP);

    return (() => {
      if(forgotPwdInitTimeStamp && isSubmitted){
        props.createPwdAnalytics(getCreatePwdAnalytics(forgotPwdInitTimeStamp));
        SessionStorage.removeItem(LoginConstants.FORGOT_PWD_INIT_TIMESTAMP);
      }
    });
  }, []);

  const resetPwdValidationLists = (...pwdValidationLists) => {
    pwdValidationLists.forEach(item => {
      item.forEach(item => {
        item.valid = false;
      });
    });
  };

  const resetValidationErrors = (resetValidationListStatus) => {
    const {addAppContext, pwdMustContainList, pwdCannotContainList} = props;
    setPasswordsMatch(true);
    addAppContext({submitCredentialsError: null});
    if (resetValidationListStatus) {
      resetPwdValidationLists(pwdMustContainList, pwdCannotContainList);
    }
  };

  const isPasswordsMatch = () => {
    return (newPassword && confirmPassword && newPassword === confirmPassword);
  };

  const onCreatePassword = () => {
    const {createNewPassword, pwd, addAppContext, submitCredentialsError} = props;
    setPasswordsMatch(isPasswordsMatch());
    if (isPasswordsMatch()) {
      createNewPassword({
        password: pwd,
        newPassword,
        confirmPassword
      });
      setIsSubmitted(true);
    } else {
      submitCredentialsError && addAppContext({submitCredentialsError: null});
    }
  };

  const onNewPasswordChange = (event) => {
    const {value} = event.target;
    const {userId = '', pwdMustContainList, pwdCannotContainList} = props;
    const hasUppercaseAlphabets = value.match(/[A-Z]/);
    const hasLowercaseAlphabets = value.match(/[a-z]/);
    const hasNumber = /\d/.test(value);
    const hasSymbols = value.match(/[^a-zA-Z0-9\s]/);
    const textLength = value.length;
    const hasWhitespaces = value.match(/\s/);

    if (value) {
      pwdMustContainList[0].valid = !value || textLength >= 8 ? 'valid' : 'invalid';
      pwdMustContainList[1].valid = !value || (hasUppercaseAlphabets && hasLowercaseAlphabets) ? 'valid' : 'invalid';
      pwdMustContainList[2].valid = !value || (hasNumber || hasSymbols) ? 'valid' : 'invalid';
      pwdCannotContainList[0].valid = !hasFoundRepeatingCharacter(value, 3) ? 'valid' : 'invalid';
      pwdCannotContainList[1].valid = !hasFoundSequentialCharacter(value) ? 'valid' : 'invalid';
      pwdCannotContainList[2].valid = (textLength < 6 || (textLength >= 6 && !/^\d+$/.test(value.substr(textLength - 6, textLength)))) ? 'valid' : 'invalid';
      pwdCannotContainList[3].valid = !value.toUpperCase().includes(userId.toUpperCase()) ? 'valid' : 'invalid';
      pwdCannotContainList[4].valid = !value || !hasWhitespaces ? 'valid' : 'invalid';
    } else {
      resetValidationErrors(true);
    }
    setNewPassword(value);
  };

  const onConfirmPasswordChange = (event) => {
    const {value} = event.target;
    !value && resetValidationErrors();
    setConfirmPassword(value);
  };

  const helpClickHandler = () => {
    props.isShowMosaicHelp(true);
  };

  const isConfirmPwdFieldDisabled = () => {
     return isEmpty(newPassword) || (props.pwdMustContainList && props.pwdCannotContainList
      && props.pwdMustContainList.find(validationItem => validationItem.valid === 'invalid')
      || props.pwdCannotContainList.find(validationItem => validationItem.valid === 'invalid')
      || props.isLoginInProgress);
  };

  const isSubmitDisabled = () => {
    return isConfirmPwdFieldDisabled() || (!confirmPassword || confirmPassword.length === 0);
  };

  const {
    labels, pwdMustContainList, pwdCannotContainList, submitCredentialsError,
    isShowMosaicHelpFlag, isLoginInProgress
  } = props;
  const leftPanelProps = {
    title: labels.tkLeftPanelHeader,
    subTitle: labels.tkLeftPanelSubHeader
  };

  return (
    <React.Fragment>
      <Conditional condition={isLoginInProgress}>
        <ThreeSquaresLoader />
      </Conditional>
      <div className="create-password-container">
        <div className="create-password-container__left-panel">
          <div className="panel-title">
            <LeftPanel {...leftPanelProps} />
          </div>
        </div>
        {
          !isShowMosaicHelpFlag ?
            <div className="create-password-container__right-panel">
              <div className="fields">
                <div className="fields__header">
                  {labels.tkCreatePasswordHeader}
                </div>
                <div
                  className="fields__subheader fields__subheader--alert"
                  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(labels.tkCreatePasswordSubheader)}}
                />
                <form onSubmit={onCreatePassword}>
                  <div className="fields__inputs">
                    {
                      submitCredentialsError &&
                      <div className="fields__error-text">
                        {submitCredentialsError}
                      </div>
                    }
                    <div className="input-container">
                      <Input
                        autoFocus={true}
                        name="newPassword"
                        placeholder={labels.tkCreateNewPasswordPlaceholder}
                        value={newPassword}
                        type={newPasswordEnabled ? 'password' : 'text'}
                        size="md"
                        onChange={onNewPasswordChange}
                        trailingContent={newPasswordTrailingContent}
                        className={cn({'password-spaced': newPassword}, 'login-input')}
                      />
                    </div>
                    <div className="fields__subheader">
                      {labels.tkPasswordMustContain}
                    </div>
                    <div className="fields__list">
                      {Array.isArray(pwdMustContainList) ? pwdMustContainList.map(item =>
                        (<ValidationItem
                          key={item.text}
                          customClass="validation-list"
                          className="check-mark"
                          iconStatus={item.valid}
                          lineText={item.text} />)
                      ) : null}
                    </div>
                    <div className="fields__subheader">
                      {labels.tkPasswordCannotContain}
                    </div>
                    <div className="fields__list">
                      {Array.isArray(pwdCannotContainList) ? pwdCannotContainList.map(item =>
                        (<ValidationItem
                          key={item.text}
                          customClass="validation-list"
                          className="check-mark"
                          iconStatus={item.valid}
                          lineText={item.text} />)
                      ) : null}
                    </div>
                    <div className="input-container">
                      <Input
                        name="confirmPassword"
                        placeholder={labels.tkConfirmPasswordPlaceholder}
                        value={confirmPassword}
                        type={confirmPasswordEnabled ? 'password' : 'text'}
                        size="md"
                        onChange={onConfirmPasswordChange}
                        trailingContent={confirmPasswordTrailingContent}
                        disabled={isConfirmPwdFieldDisabled()}
                        className={cn({'password-spaced': confirmPassword}, 'login-input')}
                      />
                    </div>
                    <Conditional condition={!passwordsMatch}>
                      <ValidationItem
                        key={labels.tkPasswordMatch}
                        customClass="pwd-donotmatch"
                        className="check-mark"
                        iconStatus='invalid'
                        lineText={labels.tkPasswordMatch}
                      />
                    </Conditional>
                  </div>
                  <div className="login-button">
                    <Button
                      extraProps={{'analytics-attribute': analyticsEventName.LOGIN_PAGE_CREATE_PASSWORD_BUTTON}}
                      type="submit"
                      customClass="button-primary-transparent"
                      label={labels.tkCreatePassword}
                      isDisabled={isSubmitDisabled()}
                      clickHandler={onCreatePassword} />
                  </div>
                </form>
                <div className="login-sub-button-container">
                  <span
                    analytics-attribute={analyticsEventName.CREATE_PASSWORD_PAGE_HELP_LINK}
                    className="login-sub-button"
                    onClick={helpClickHandler}>
                    {labels.tkNeedHelp}
                  </span>
                </div>
              </div>
            </div> :
            <HelpContainer />
        }
      </div>
    </React.Fragment>
  );
};

CreatePassword.propTypes = {
  labels: PropTypes.object,
  createNewPassword: PropTypes.func,
  pwdMustContainList: PropTypes.array,
  pwdCannotContainList: PropTypes.array,
  submitCredentialsError: PropTypes.string,
  isShowMosaicHelp: PropTypes.func,
  isShowMosaicHelpFlag: PropTypes.bool,
  isLoginInProgress: PropTypes.bool,
  userId: PropTypes.string,
  nextStep: PropTypes.string,
  history: PropTypes.object,
  addAppContext: PropTypes.func,
  createPwdAnalytics: PropTypes.func,
  pwd: PropTypes.string
};
