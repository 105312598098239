import {call, put, select, takeEvery} from 'redux-saga/effects';
import sha256 from 'sha256';
import isObject from 'lodash/isObject';
import ServiceManager from '../../../services';
import reportError from '../errorHandler';
import AppConstants from '../../../constants/appConstants';
import {
  LOGIN_NEXT_STEP_ERROR, LOGIN_NEXT_STEP_CODES, CHECK_CREDENTIAL_ERROR_CODES, CHANGE_PASSWORD_ERROR_CODES,
  CHANGE_PIN_ERROR_CODES, LOGIN_ERROR_CODES, FORGOT_PASSWORD_ERROR_CODES
} from '../../../constants/pageConstants';
import {isLoginInProgress, isMFAFlowInProgress, isShowMosaicHelp} from '../../../actions/page/login';
import {addAppData, addAppContext} from '../../../actions/app';
import {addPageData} from '../../../actions/page';
import appActionTypes from '../../../actions/app/actionTypes';
import loginActionTypes from '../../../actions/page/login/actionTypes';
import {
  resumePathSelector, submitCredentialsErrorSelector, pingConfigSelector, pingDownTriesSelector,
  isTmxProfilingCompleteSelector, isInternalUserMFASelector, internalUserMFAParamsSelector, isMFAFlowInProgressSelector
} from '../../../selectors/pages/login';
import history from '../../../utils/history';
import endPointsMapper from '../../../configs/endPointsMapper/login';
import {
  getCookie,
  getTokenValue,
  postToNewPage,
  getElementValueFromHtmlContent,
  getRandomIdForThreatMetrix,
  getLoginRoute,
  getLoginDomainMapper
} from '../../../utils/commonUtils';
import GLSError from '../../../error';
import errorTypes from '../../../error/errorType';
import translator from '../../../services/translator';
import {eventAnalytics} from '../../../actions/sagas';
import {
  getLoadOfCreatePasswordData,
  getLoadOfCreatePinData,
  getLoadOfPageLoginData,
  setLoginMethodIdentifier
} from '../../../utils/loginAnalytics';

const {translate: t} = translator;

export function* loginActionWatchers() {
  yield takeEvery(loginActionTypes.SUBMIT_CREDENTIALS, onSubmitCredentialsToPing);
  yield takeEvery(loginActionTypes.CREATE_NEW_PASSWORD, onSubmitNewPasswordOrPinOrUidOrOtp);
  yield takeEvery(loginActionTypes.CREATE_NEW_PIN, onSubmitNewPasswordOrPinOrUidOrOtp);
  yield takeEvery(loginActionTypes.SUBMIT_FPW_UID, onSubmitNewPasswordOrPinOrUidOrOtp);
  yield takeEvery(loginActionTypes.SUBMIT_FPW_OTP, onSubmitNewPasswordOrPinOrUidOrOtp);
  yield takeEvery(appActionTypes.IS_TMX_PROFILING_COMPLETE, onTmxProfilingEventComplete);
}

export function* onTmxProfilingEventComplete() {
  const isInternalUserMFA = yield select(isInternalUserMFASelector);
  if (isInternalUserMFA) {
    const internalUserMFAParams = yield select(internalUserMFAParamsSelector);
    yield call(startMFACheckFlow, internalUserMFAParams);
  }
}

export function* fetchDeepLinkMappings() {
  const state = yield select();
  const args = {state, endPointConfig: endPointsMapper.FETCH_DEEPLINK_MAPPINGS};
  try {
    const {pathMapping} = yield call(ServiceManager, args);
    yield put(addAppData({pathMapping}));
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* fetchLoginAppLabels() {
  const state = yield select();
  const args = {state, endPointConfig: endPointsMapper.FETCH_LABELS};
  try {
    const labels = yield call(ServiceManager, args);
    translator.labels = labels;
    yield put(addAppData({labels}));
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* fetchLoginAEMConfiguration() {
  const state = yield select();
  const args = {state, endPointConfig: endPointsMapper.FETCH_LOGIN_CONFIGURATIONS};
  try {
    const loginConfiguration = yield call(ServiceManager, args);
    yield put(addAppData({loginConfiguration}));
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* fetchHelpContents() {
  const state = yield select();
  const args = {state, endPointConfig: endPointsMapper.FETCH_HELP_CONTENT_CONFIGURATIONS};
  try {
    const helpServiceResponse = yield call(ServiceManager, args);
    if (helpServiceResponse) {
      const {helpContents = []} = helpServiceResponse;
      yield put(addAppData({
        helpContents
      }));
    }
  } catch (error) {
    yield call(reportError, error);
  }
}

export function* createPasswordPageLoad() {
  yield call(fetchPwdAndPinValidationData);
  yield put(eventAnalytics(getLoadOfCreatePasswordData()));

}

export function* createPinPageLoad() {
  yield call(fetchPwdAndPinValidationData);
  yield put(eventAnalytics(getLoadOfCreatePinData()));
}

export function* loginPageLoad() {
  yield put(eventAnalytics(getLoadOfPageLoginData()));
}

export function* fetchPwdAndPinValidationData() {
  const state = yield select();
  const validationArgs = {
    state,
    endPointConfig: endPointsMapper.PWD_AND_PIN_VALIDATION_ITEMS
  };
  try {
    const {pwdMustContainList, pwdCannotContainList, pinValidationList} = yield call(ServiceManager, validationArgs);
    yield put(addPageData({pwdMustContainList, pwdCannotContainList, pinValidationList}));
  } catch (error) {
    throw new GLSError(errorTypes.UNKNOWN_SERVICE_ERROR, error);
  }
}

/* eslint-disable camelcase */
export function* fetchPINGConfig() {
  const state = yield select();
  const configArgs = {
    state,
    endPointConfig: endPointsMapper.PING_CONFIG
  };
  try {
    const pingConfig = yield call(ServiceManager, configArgs);
    const mfaSessionId = getRandomIdForThreatMetrix();
    const pingConfigJson = pingConfig.hostname ? pingConfig : JSON.parse(pingConfig);
    const {
      access_token_manager_id, authPath, authURL, client_id, hostname,
      logoutParams, mfaURLs, minAuthLevel, nonce, pfidpadapterids,
      redirect_uri, reset, response_mode, response_type, mfaRiskEngine, mfaTmxDomain, mfaTmxOrgId
    } = pingConfigJson;
    const brandId = getLoginDomainMapper();
    const pfidpadapterid = pfidpadapterids[brandId];
    const mfaURL = mfaURLs[brandId];
    const pingConfigParams = {
      access_token_manager_id,
      authPath,
      authURL,
      client_id,
      hostname,
      logoutParams,
      mfaURL,
      minAuthLevel,
      nonce,
      pfidpadapterid,
      reset,
      response_mode,
      response_type,
      redirect_uri: hostname + redirect_uri,
      state: sha256(getCookie(AppConstants.STATE_COOKIE)),
      sessionId: mfaSessionId
    };
    // MFA Config
    const mfaConfig = {
      mfaRiskEngine,
      mfaTmxDomain,
      mfaTmxOrgId,
      mfaSessionId
    };
    yield put(addAppContext({pingConfig: pingConfigParams, mfaConfig}));
  } catch (error) {
    throw new GLSError(errorTypes.UNKNOWN_SERVICE_ERROR, error);
  }
}

export function* initialLogin() {
  const state = yield select();
  const pingConfig = yield select(pingConfigSelector);
  const endPointConfig = {
    ...endPointsMapper.INITIAL_PING_CALL,
    path: pingConfig.authURL + pingConfig.authPath
  };
  const loginCallArgs = {
    state,
    endPointConfig,
    serviceType: AppConstants.PING,
    serviceSubType: AppConstants.PING_INITIAL_CALL
  };
  yield put(isLoginInProgress(true));
  try {
    yield call(submitCredentialsToPing, {
      callAttributes: loginCallArgs,
      initialCall: true
    });
  } catch (error) {
    console.log(`Error Details => ${error}`);
    throw new GLSError(errorTypes.UNKNOWN_SERVICE_ERROR, error);
  }
}

export function* onSubmitCredentialsToPing({data}) {
  yield put(isLoginInProgress(true));
  try {
    const state = yield select();
    const pingConfig = yield select(pingConfigSelector);
    const endPointConfig = {
      ...endPointsMapper.SUBMIT_CREDENTIALS,
      path: pingConfig.authURL + (yield select(resumePathSelector))
    };
    const loginCall = {
      state,
      endPointConfig,
      payLoad: {
        username: data.username,
        password: data.password
      },
      serviceType: AppConstants.PING
    };
    /* Mark the Password based login identifier here, to capture the analytics,
       after logging into the application */
    yield call(setLoginMethodIdentifier, 1);
    yield call(submitCredentialsToPing, {
      callAttributes: loginCall,
      stateAttributes: data
    });
  } catch (error) {
    // We got some unexpected response from PING
    yield put(isLoginInProgress(false));
    yield put(addAppContext({
      submitCredentialsError: `${t('tkSomethingWentWrongError')} (ERR10)`
    }));
  }
}

export function* onSubmitNewPasswordOrPinOrUidOrOtp({data, type}) {
  yield put(isLoginInProgress(true));
  try {
    const state = yield select();
    const pingConfig = yield select(pingConfigSelector);
    let endPointObj;
    let payLoad;

    if (type === loginActionTypes.CREATE_NEW_PIN) {
      endPointObj = endPointsMapper.CREATE_NEW_PIN;
      payLoad = {
        username: data.username,
        pin: data.newPin
      };
    } else if (type === loginActionTypes.SUBMIT_FPW_UID) {
      endPointObj = endPointsMapper.SUBMIT_FPW_UID;
      payLoad = {
        passwordReset: true,
        username: data.uid
      };
      yield put(addAppContext({username: data.uid}));
    } else if (type === loginActionTypes.SUBMIT_FPW_OTP) {
      endPointObj = endPointsMapper.SUBMIT_FPW_OTP;
      payLoad = {
        otp: data.otp
      };
    } else {
      endPointObj = endPointsMapper.CREATE_NEW_PASSWORD;
      const passWordProp = data.password ? {password: data.password} : {};
      payLoad = {
        ...passWordProp,
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword
      };
    }

    const endPointConfig = {
      ...endPointObj,
      path: pingConfig.authURL + (yield select(resumePathSelector))
    };
    const createNewCall = {
      state,
      endPointConfig,
      payLoad,
      serviceType: AppConstants.PING
    };
    yield call(submitCredentialsToPing, {
      callAttributes: createNewCall,
      stateAttributes: data
    });
  } catch (error) {
    // We got some unexpected response from PING
    yield put(isLoginInProgress(false));
    yield put(addAppContext({
      submitCredentialsError: `${t('tkSomethingWentWrongError')} (ERR11)`
    }));
  }
}

function* submitCredentialsToPing(attributes) {
  const {initialCall, callAttributes} = attributes;
  if (!initialCall) {
    yield put(addAppContext({submitCredentialsError: null}));
  }
  let isKeepLoader = false;
  const pingDownTries = yield select(pingDownTriesSelector);

  try {
    const credentialSubmissionResponse = yield call(ServiceManager, callAttributes);
    if (credentialSubmissionResponse) {
      // HTML Response
      if (!isObject(credentialSubmissionResponse) && credentialSubmissionResponse.includes(AppConstants.HTML)) {
        const accessToken = getElementValueFromHtmlContent(credentialSubmissionResponse, 'access_token');
        // PING is down
        if (!accessToken) {
          yield put(addAppContext({submitCredentialsError: `${t(LOGIN_NEXT_STEP_ERROR.PING_DOWN)} (ERR12)`}));
          yield put(addAppContext({pingDownTries: parseInt(pingDownTries) + 1}));
          if (pingDownTries < 5) {
            yield call(initialLogin);
          }
        } else {  //  Access token received
          isKeepLoader = true;
          /* This means access token was issued through SSO, mark the login identifier here,
             to capture the analytics, after logging into the application */
          if (callAttributes && callAttributes.serviceSubType === AppConstants.PING_INITIAL_CALL) {
            yield call(setLoginMethodIdentifier, 2);
          }
          yield call(processAccessToken, {response: credentialSubmissionResponse});
        }
      } else { // Text/Html response
        const pingResponse = JSON.parse(credentialSubmissionResponse) || {};
        const {nextStepCode, errorMessage} = pingResponse;
        isKeepLoader = [
          LOGIN_NEXT_STEP_CODES.MFA_CHECK_REQUIRED,
          LOGIN_NEXT_STEP_CODES.CHANGE_PASSWORD
        ].includes(nextStepCode) && !errorMessage;

        if (errorMessage === LOGIN_ERROR_CODES.WARN_USERNAME_LOCKED) {
          yield put(isShowMosaicHelp(true));
          yield put(addPageData({isUserLocked: true}));
        } else {
          switch (nextStepCode) {
            case LOGIN_NEXT_STEP_CODES.MFA_CHECK_REQUIRED: {
              const isTmxProfilingComplete = yield select(isTmxProfilingCompleteSelector);
              if (!isTmxProfilingComplete) {
                yield put(addAppContext({
                  isInternalUserMFA: true,
                  internalUserMFAParams: {response: pingResponse, attributes}
                }));
              } else {
                yield call(startMFACheckFlow, {response: pingResponse, attributes});
              }
              break;
            }
            case LOGIN_NEXT_STEP_CODES.FORGOT_PASSWORD_RESET:
            case LOGIN_NEXT_STEP_CODES.CHANGE_PASSWORD:
            case LOGIN_NEXT_STEP_CODES.NEW_PIN_REQUIRED: {
              yield call(startChangePasswordAndPinFlow, {response: pingResponse});
              break;
            }
            case LOGIN_NEXT_STEP_CODES.FORGOT_PASSWORD_OTP: {
              yield call(startForgotPasswordFlow, {response: pingResponse});
              break;
            }
            default: {
              yield call(handleIncorrectResponse, {
                response: pingResponse,
                initialCall
              });
            }
          }
        }
      }
    } else {
      // We got some unexpected response from PING
      yield put(addAppContext({
        submitCredentialsError: `${t('tkSomethingWentWrongError')} (ERR01)`
      }));
    }
  } catch (error) {
    console.log(`Error occurred => ${error}`);
    // We got some unexpected response from PING
    yield put(addAppContext({
      submitCredentialsError: `${t('tkSomethingWentWrongError')} (ERR02)`
    }));
  } finally {
    if (!isKeepLoader) {
      yield put(isLoginInProgress(false));
    }
  }
}

function* startMFACheckFlow({response, attributes}) {
  const {callAttributes: {serviceSubType} = {}, stateAttributes} = attributes;
  const isMFAFlowInProgressFlag = yield select(isMFAFlowInProgressSelector);
  if (!isMFAFlowInProgressFlag) {
    yield put(isMFAFlowInProgress(true));
    const mfaToken = getTokenValue('mfaToken', response.infoMessage);
    const accessToken = getTokenValue('access_token', response.infoMessage);
    const pingConfig = yield select(pingConfigSelector);
    endPointsMapper.MFA_INIT.path = pingConfig.mfaURL + endPointsMapper.MFA_INIT.path;

    /* This means users gets into MFA through SSO, mark the login identifier here,
       to capture the analytics */
    if (serviceSubType === AppConstants.PING_INITIAL_CALL) {
      yield call(setLoginMethodIdentifier, 2);
    }

    if (stateAttributes) {
      const {username, rememberUsername} = stateAttributes;
      if (rememberUsername) {
        localStorage.setItem(AppConstants.LOCAL_STORAGE_USER_KEY, btoa(username));
      } else {
        const prevUser = atob(localStorage.getItem(AppConstants.LOCAL_STORAGE_USER_KEY));
        if (prevUser === username) {
          localStorage.removeItem(AppConstants.LOCAL_STORAGE_USER_KEY);
        }
      }
    }

    try {
      postToNewPage({
        url: endPointsMapper.MFA_INIT.path,
        params: {access_token: accessToken, mfaToken},
        method: 'POST'
      });
    } catch (error) {
      throw new GLSError(errorTypes.UNKNOWN_SERVICE_ERROR, error);
    }
  }
}

function* startChangePasswordAndPinFlow({response = {}}) {
  const {errorMessage, detailedErrorCode, resumePath, nextStepCode} = response;
  let route;
  let errorCode;
  yield put(addAppContext({nextStep: nextStepCode, resumePath}));

  if (nextStepCode === LOGIN_NEXT_STEP_CODES.NEW_PIN_REQUIRED) {
    route = getLoginRoute(AppConstants.CREATE_PIN_ROUTE);
    errorCode = t(CHANGE_PIN_ERROR_CODES[detailedErrorCode || errorMessage]);
  } else {
    route = getLoginRoute(AppConstants.CREATE_PASSWORD_ROUTE);
    errorCode = t(CHANGE_PASSWORD_ERROR_CODES[detailedErrorCode || errorMessage]);
  }

  if (errorMessage && window.location.pathname.includes(route)) {
    const submitCredentialsError = (errorCode || t(LOGIN_NEXT_STEP_ERROR.PING_DOWN));
    yield put(addAppContext({submitCredentialsError: `${submitCredentialsError} (ERR08)`}));
  } else {
    history.push(route);
  }
}

function* startForgotPasswordFlow({response = {}}) {
  const {errorMessage, resumePath, nextStepCode} = response;
  yield put(addAppContext({nextStep: nextStepCode, resumePath}));

  const route = getLoginRoute(AppConstants.FORGOT_PASSWORD_ROUTE);
  const errorCode = t(FORGOT_PASSWORD_ERROR_CODES[errorMessage]);

  if (errorMessage && window.location.pathname.includes(route)) {
    const submitCredentialsError = (errorCode || t(LOGIN_NEXT_STEP_ERROR.PING_DOWN));
    yield put(addAppContext({submitCredentialsError: `${submitCredentialsError} (ERR09)`}));
  }
}

function* handleIncorrectResponse({response = {}, initialCall = false}) {
  const {nextStepCode, infoMessage, errorMessage, detailedErrorCode, resumePath} = response;
  let submitCredentialsError = yield select(submitCredentialsErrorSelector);
  if (!initialCall) {
    const {pathname} = window.location;
    const errorCode = (detailedErrorCode || infoMessage || errorMessage);
    switch (nextStepCode) {
      case LOGIN_NEXT_STEP_CODES.CHECK_CREDENTIAL: {
        submitCredentialsError = ((pathname === getLoginRoute(AppConstants.LOGIN_ROUTE)) && t(CHECK_CREDENTIAL_ERROR_CODES[errorCode])) || t(LOGIN_NEXT_STEP_ERROR.PING_DOWN);
        if (submitCredentialsError) {
          const additionalErrorCode = detailedErrorCode ? '(ERR03)' : '(ERR04)'
          submitCredentialsError = `${submitCredentialsError} ${additionalErrorCode}`;
        }
        break;
      }
      case LOGIN_NEXT_STEP_CODES.CHANGE_PASSWORD: {
        submitCredentialsError = ((pathname === getLoginRoute(AppConstants.CREATE_PASSWORD_ROUTE)) && t(CHANGE_PASSWORD_ERROR_CODES[errorCode])) || t(LOGIN_NEXT_STEP_ERROR.PING_DOWN);
        if (submitCredentialsError) {
          const additionalErrorCode = detailedErrorCode ? '(ERR05)' : '(ERR06)'
          submitCredentialsError = `${submitCredentialsError} ${additionalErrorCode}`;
        }
        break;
      }
      default: {
        submitCredentialsError = `${t(LOGIN_NEXT_STEP_ERROR.PING_DOWN)} (ERR07)`;
      }
    }
  }
  yield put(addAppContext({nextStep: nextStepCode, submitCredentialsError, resumePath}));
  yield put(isLoginInProgress(false));
}

function processAccessToken({response}) {
  document.body.innerHTML = response;
  document.forms[0].submit();
}
